<template>
  <div>
    <v-snackbar v-model="snackbar" top :color="color">
      <span v-html="message"></span>
      <v-btn dark text @click="snackbar = false"> 閉じる </v-btn>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog v-model="pwctlDialog" max-width="480">
        <v-card>
          <v-card-title> パスワード管理 </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12"
                ><v-text-field
                  v-model="authContext.user"
                  label="user name"
                  :disabled="maskName"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  v-model="authContext.password"
                  label="password"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-divider />
            <v-row justify="space-around">
              <v-col>
                <v-btn @click="pwctlDialog = false">キャンセル</v-btn>
              </v-col>
              <v-col>
                <v-btn @click="updatePwdUser">決定</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-breadcrumbs :items="crumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-card-subtitle>{{ floor }} ユーザーリスト</v-card-subtitle>
            <v-spacer />
            <v-btn
              @click="
                pwctlDialog = true
                maskName = false
                authContext.user = ''
              "
              >ユーザー追加</v-btn
            >
          </v-row>
        </v-card-title>
        <v-list>
          <div v-for="(user, i) in users" :key="`${user.Name}-${i}`">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green">mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-row>
                  <v-col cols="3">
                    {{ user.userName }}
                  </v-col>
                  <v-col cols="3"> 作成日 {{ user.createdAt }} </v-col>
                  <v-spacer />
                  <v-col cols="3">
                    <v-btn text color="blue" @click="c(user.userName)">
                      パスワード変更
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn text color="blue" @click.prevent="d(user.userName)">
                      削除
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-card>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'

const api = axios.create({
  baseURL: 'https://tact-auth-l2oi27p23a-an.a.run.app',
  //baseURL: 'http://localhost:8080',
})

export default Vue.extend({
  props: {
    floor: {
      type: String,
    },
  },
  methods: {
    c(user) {
      console.log('change', user)
      this.authContext.user = user
      this.maskName = true
      this.pwctlDialog = true
    },
    async d(user) {
      console.log('delete', user)
      try {
        await api.delete(`/user-access-ctl/${this.floor}/users/${user}`)
        location.reload()
      } catch (err) {
        this.messge = `サーバーでエラーが発生しました`
        this.color = 'error'
        this.snackbar = true
      }
    },
    async updatePwdUser() {
      console.log(this.authContext.user, this.authContext.password)
      try {
        await api.post(`/user-access-ctl/${this.floor}/users/`, {
          userName: this.authContext.user,
          passwd: this.authContext.password,
        })
        location.reload()
      } catch (err) {
        this.messge = `サーバーでエラーが発生しました`
        this.color = 'error'
        this.snackbar = true
      }
    },
  },
  async mounted() {
    this.crumbs.push({ text: this.floor, disabled: true })
    try {
      const { data } = await api.get(`/user-access-ctl/${this.floor}/users/`)
      console.log(data)
      data.users.map((val) => {
        const day = moment(val.createAt)
        this.users.push({
          createdAt: day.format('YYYY-MM-DD'),
          userName: val.userName,
        })
      })
    } catch (err) {
      console.log(err)
    }
  },
  data: () => ({
    message: '',
    color: '',
    snackbar: false,
    pwctlDialog: false,
    maskName: false,
    authContext: {
      user: '',
      password: '',
    },
    users: [],
    crumbs: [{ text: 'top', disabled: false, to: { name: 'top' } }],
  }),
})
</script>
